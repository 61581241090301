<template>
  <div v-if="isLoading" class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div slot="main-content" v-if="!isLoading">
    <div class="my-container container-fluid">
      <div class="row">
        <Card v-for="(card, index) in cards" :key="index" :card="card" />
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Dashboard/Cards/Card/Card";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";

export default {
  name: 'Cards',
  components: {Card},
  data() {
    return {
      isLoading: false,
      dash: {
        games: null,
        teams: null,
        reschedule: null,
        spt: null,
        unread: null,
        message: null,
        clubs: null,
        user: null,
        totalUser: null,
        open: null,
        accepted: null,
        declined: null,
        calledBack: null,
        topMessage: null,
        topReschedule: null
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    cards() {
      const dash = [
        {
          text: "Spiele",
          number: this.dash.games,
          icon: 'basketball',
          color: 'white',
          link: {
            name: 'schedule'
          }
        },
        {
          text: "Teams",
          number: this.dash.teams,
          icon: 'shirt',
          color: 'white',
          link: {
            name: 'schedule'
          }
        },
        {
          text: "Verlegungen",
          number: this.dash.reschedule,
          icon: 'arrow-right-arrow-left',
          color: 'white',
          link: {
            name: 'schedule'
          }
        },
        {
          text: "Nachrichten",
          number: this.dash.message,
          unread: this.dash.unread,
          icon: 'envelope',
          color: 'white',
          link: {
            name: 'schedule'
          },
          filter: {
            unread: this.dash.unread > 0,
            message: this.dash.unread === 0
          }
        },
        {
          text: "Offen",
          number: this.dash.open,
          icon: 'book-open',
          color: 'white',
          link: {
            name: 'schedule'
          },
          filter: {open: true}
        },
        {
          text: "Angenommen",
          number: this.dash.accepted,
          icon: 'thumbs-up',
          color: 'white',
          link: {
            name: 'schedule'
          },
          filter: {accepted: true}
        },
        {
          text: "Abgelehnt",
          number: this.dash.declined,
          icon: 'thumbs-down',
          color: 'white',
          link: {
            name: 'schedule'
          },
          filter: {declined: true}
        },
        {
          text: "Zurückgezogen",
          number: this.dash.calledBack,
          icon: 'clock-rotate-left',
          color: 'white',
          link: {
            name: 'schedule'
          },
          filter: {calledBack: true}
        },
        {
        text: "Spielplantag offen",
        number: this.dash.spt,
        icon: 'triangle-exclamation',
        color: 'white',
        link: {
          name: 'schedule'
        },
        filter: {spt: true}
      },
      ]

      return !this.isAdmin ? dash : [...dash, ...this.admin]
    },

    admin() {
      return [
        {
          text: "Nutzer",
          number: this.dash.totalUser,
          icon: 'users',
          color: 'white',
        },
        {
          text: "Vereinsverantwortliche",
          number: this.dash.manager,
          icon: 'user-tie',
          color: 'white',
        },
        {
          text: "Teamverantwortliche",
          number: this.dash.user,
          icon: 'user',
          color: 'white',
        },
        {
          text: "Vereine",
          number: this.dash.clubs,
          icon: 'users-between-lines',
          color: 'white',
        },
        {
          text: "Nachrichten",
          smallNumber: this.dash.topMessage,
          icon: 'trophy',
          color: 'white',
        },
        {
          text: "Verlegungen",
          smallNumber: this.dash.topReschedule,
          icon: 'medal',
          color: 'white',
        },
      ]
    },
  },
  methods: {},
  async mounted() {
    this.isLoading = true
    this.dash = await axios.get('match/dash').then(res => res.data).finally(() => this.isLoading = false)
  }
};
</script>
<style>
.my-container {
  padding: 20px;
  max-width: 700px;
}

.code {
  min-width: 150px;
}
</style>