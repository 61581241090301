import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc'

export default {
    install(app, options) {
        dayjs.extend(timezone)
        dayjs.extend(utc)

        const $formatDate = function (dateString) {
            const date = dayjs.tz(dateString, "Europe/Berlin");
            // Then specify how you want your dates to be formatted
            return date.format("DD.MM.YYYY");
        };
        const $formatTableDate = function (dateString) {
            const date = dayjs.tz(dateString, "Europe/Berlin");
            // Then specify how you want your dates to be formatted
            return date.format("YYYY-MM-DD");
        };
        const $formatTime = function (dateString) {
            const date = dayjs.tz(dateString, "Europe/Berlin");
            // Then specify how you want your dates to be formatted
            return date.format("HH:mm");
        };
        const $formatEndTime = function (dateString) {
            const date = dayjs.tz(dateString, "Europe/Berlin").add(2, 'h');

            // Then specify how you want your dates to be formatted
            return date.format("HH:mm");
        };

        const $fetchFromObject = function (obj, prop) {

            if (typeof obj === "undefined") {
                return false;
            }

            var _index = prop.indexOf(".");
            if (_index > -1) {
                return fetchFromObject(obj[prop.substring(0, _index)], prop.substr(_index + 1));
            }

            return obj[prop];
        };
        const $sortFunction = function (obj, sort, direction) {

            let sortedArray;
            if (sort == "guestTeam") {
                sortedArray = obj.sort($guest);

            } else if (sort == "homeTeam") {
                sortedArray = obj.sort($home);

            } else if (sort == "date") {
                sortedArray = obj.sort($date);

            } else if (sort == "dateOnly") {
                sortedArray = obj.sort($dateOnly);
            } else if (sort == "text") {
                sortedArray = obj.sort($locale);
            } else if (sort == "teamName") {
                sortedArray = obj.sort($teamName);

            } else if (sort == "teamAge") {
                sortedArray = obj.sort($teamAge);

            } else if (sort == "teamGender") {
                sortedArray = obj.sort($teamGender);

            }
            else if (sort == "clubUserTeam"){
                sortedArray = obj.sort($clubUserTeam)
            }else {
                sortedArray = obj.sort($matchNo);
            }


            if (direction == "desc") {
                return sortedArray.reverse();
            } else {
                return sortedArray;
            }


        };
        const $teamName = function (a, b) {
            return $locale(a.teamname, b.teamname);
        };
        const $clubUserTeam = function (a, b) {
            return $locale(a.label, b.label);
        };
        const $teamGender = function (a, b) {
            return $locale(a.geschlecht, b.geschlecht);
        };
        const $teamAge = function (a, b) {
            return $locale(a.akName, b.akName);
        };
        const $home = function (a, b) {
            return $locale(a.actualHomeTeam, b.actualHomeTeam);
        };
        const $guest = function (a, b) {
            return $locale(a.actualGuestTeam, b.actualGuestTeam);
        };

        const $matchNo = function (a, b) {
            if (a.matchNo < b.matchNo) {
                return -1;
            }
            if (a.matchNo > b.matchNo) {
                return 1;
            }
            return 0;
        };
        const $locale = function (a, b) {
            return (a && a.localeCompare(b, "de-DE-u-kn-true"));
        };
        const $date = function (a, b) {
            if (Date.parse(a.actualDate) < Date.parse(b.actualDate)) {
                return -1;
            }
            if (Date.parse(a.actualDate) > Date.parse(b.actualDate)) {
                return 1;
            }
            return 0;
        };
        const $dateOnly = function (a, b) {
            if (Date.parse(a) < Date.parse(b)) {
                return -1;
            }
            if (Date.parse(a) > Date.parse(b)) {
                return 1;
            }
            return 0;
        };
        const $groupBy = function (list, keyGetter) {
            const map = new Map();
            list.forEach((item) => {
                const key = keyGetter(item);
                const collection = map.get(key);
                if (!collection) {
                    map.set(key, [item]);
                } else {
                    collection.push(item);
                }
            });
            return Array.from(map);
        }
        app.provide("groupBy", $groupBy);
        app.provide("formatDate", $formatDate);
        app.provide("formatTime", $formatTime);
        app.provide("fetchFromObject", $fetchFromObject);
        app.provide("sortFunction", $sortFunction);
        app.provide("formatTableDate", $formatTableDate);
        app.provide("formatEndTime", $formatEndTime);
    }


};
